export const ratingImage = [
  {
    src: 'https://lahaus.imgix.net/static/home/google-review-symbol.svg',
    alt: 'Calificación Google',
    width: 32,
    height: 32,
    class: 'mr-8'
  },
  {
    src: 'https://lahaus.imgix.net/static/home/star-filled.svg',
    alt: 'Calificación',
    width: 30,
    height: 30
  },
  {
    src: 'https://lahaus.imgix.net/static/home/star-filled.svg',
    alt: 'Calificación',
    width: 30,
    height: 30
  },
  {
    src: 'https://lahaus.imgix.net/static/home/star-filled.svg',
    alt: 'Calificación',
    width: 30,
    height: 30
  },
  {
    src: 'https://lahaus.imgix.net/static/home/star-filled.svg',
    alt: 'Calificación',
    width: 30,
    height: 30
  },
  {
    src: 'https://lahaus.imgix.net/static/home/star-half.svg',
    alt: 'Calificación',
    width: 30,
    height: 30
  }
]
